import React, { useState, useEffect } from 'react'
import Images from '../../../Images'
import { faLocationDot } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useNavigate } from 'react-router-dom'
import { Carousel } from 'react-bootstrap'
import { ReactPhotoCollage } from "react-photo-collage";
import ReactPlayer from "react-player";
import { BeatLoader } from "react-spinners";

const ImageGallery = ({ allData }) => {
  const [loadingImages, setLoadingImages] = useState(true);
  const navigate = useNavigate(); 
  useEffect(() => {
    if (allData) {
      setLoadingImages(false);
    }
  }, [allData]);

  if (!allData) {
    return <div>Loading...</div>;
  }

  const base64ToImageUrl = (base64String) => `data:image/jpeg;base64,${base64String}`;
  const photos = allData.images.map((imageData) => ({
    source: imageData.image ? base64ToImageUrl(imageData.image) : Images.galleryimage1,
  }));

  const setting = {
    width: "auto",
    height: ["250px", "170px"],
    layout: [1, 3],
    photos: photos,
    showNumOfRemainingPhotos: true
  };

  const base64ToVideoUrl = (base64String) => base64String;
  const videos = allData.videos.map((videoData) => ({
    source: base64ToVideoUrl(videoData.video),
    type: videoData.type,
  }));

  return (
    <div className='col-xl-12 col-md-12'>
      <h4 className='text-black mx-5' style={{ marginTop: "140px" }}>
        {allData.mandapam_name ? allData.mandapam_name : ""} 
      </h4>
      <div className='d-flex justify-content-between mt-3 mb-3 mx-5'>
        <h6 className='text-black '>
          <FontAwesomeIcon className='me-1' icon={faLocationDot} />
          {allData.address ? allData.address : "Pai Vista "}
        </h6>
      </div>
      <div className='gallery-container'>
        {loadingImages ? (
          <div className="d-flex justify-content-center my-5 pt-5">
            <div className='pt-5 mt-5'>
              <BeatLoader color={"#123abc"} loading={loadingImages} />
            </div>
          </div>
        ) : (
          allData.images.length > 0 ? (
            allData.images.length > 1 ? (
              <ReactPhotoCollage {...setting} />
            ) : (
              <div className="single-image-container text-center">
                <img src={photos[0].source} alt="Single" style={{ border: "1px solid #ddd", height: "250px" }} />
              </div>
            )
          ) : (
            <div className="text-center my-5">No Images Found</div>
          )
        )}
      </div>
      {allData.videos.length > 0  && allData.videos[0].video !="" > 0? (
        <Carousel interval={null}>
          {videos.map((video, index) => (
            <Carousel.Item key={index}>
              <ReactPlayer
                url={video.source}
                pip={true}
                controls={true}
                playing={false}
              />
              <Carousel.Caption>
                <p>Double Click to Preview Video in Full Size</p>
              </Carousel.Caption>
            </Carousel.Item>
          ))}
        </Carousel>
      ) : (
        <div className="text-center my-5">No Videos Found</div>
      )}
    </div>
  )
}

export default ImageGallery
