import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react'
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { Link, NavLink, useNavigate } from 'react-router-dom';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { Icons } from '../../../Icons/IconIndex';
import "./header.css"
import Images from '../../../Images';
import axios from 'axios';
import { apiURL } from '../../../Layout/Common/confiqurl';
import Swal from 'sweetalert2';
import { Toaster, toast } from 'react-hot-toast'


const data = [
  {
    place: "Chennai",
    part: "1"
  },
  {
    place: "Kochi",
    part: "1"
  },
  {
    place: "Banglure",
    part: "1"
  },
  {
    place: "Coimbatore",
    part: "1"
  },
  {
    place: "Mumbai",
    part: "1"
  },
  {
    place: "Nodia",
    part: "1"
  },
  {
    place: "pune",
    part: "1"
  },
  {
    place: "ahmedabad",
    part: "1"
  },

]

const Header = ({ logo, city, cityId }) => {
  const [selectedCity, setSelectedCity] = useState(city || '');
  const [cityname, setcityname] = useState('');
  const [cityid, setcityid] = useState('');
  const [headercity, setheadercity] = useState(city || ''); 
  useEffect(() => {
    const fetchCityList = async () => {
      try { 
        const data = await getcitylist();
        let cityIds = [];
        const checkLocationAccess = () => {
          return new Promise((resolve, reject) => {
            navigator.geolocation.getCurrentPosition(
              () => resolve(true),
              () => resolve(false)
            );
          });
        };

        const locationAccess = await checkLocationAccess();
        if (locationAccess) { 
          const newcity = sessionStorage.getItem('citynameheadernewapi');
          const citynameheadernewapicityid = sessionStorage.getItem('citynameheadernewapicityid');

          if (newcity && newcity !== '') {
            setcityname(newcity);
          } else {
            setcityname(citynameheadernewapicityid || cityname);
          }
          cityIds = citylist.map(city => city.id);
          sessionStorage.setItem('selectedCityIds', JSON.stringify(cityIds));
          sessionStorage.setItem("citynamestore", cityname);

        } else {
          sessionStorage.setItem('selectedCityIds', JSON.stringify(cityIds));
          sessionStorage.setItem("citynamestore", cityname); 
          const newcity = sessionStorage.getItem('citynameheadernewapi');
          const citynameheadernewapicityid = sessionStorage.getItem('citynameheadernewapicityid');

          if (newcity && newcity !== '') {
            setcityname(newcity);
          } else {
            setcityname(citynameheadernewapicityid || cityname);
          }
          cityIds = data.map(city => city.id); 
        } 
      } catch (error) {
        console.error('Error fetching city list:', error);
      }
    };

    fetchCityList();
  }, []);


  const handleChange = (e) => {
    const selectedCity = e.target.value;
    const selectedCityid = e.target.value;
    setcityname(selectedCity);
    setcityid(selectedCityid)
    setSelectedCity(selectedCity)
    getlocationwithid(selectedCity);
  };
  const navigate = useNavigate()
  const [citylist, setcitylist] = useState([])
  const redirect = () => {
    navigate('/')
  }
  const getcitylist = () => { 
    axios.get(`${apiURL}/cities`)
      .then(response => {
        setcitylist(response.data.data || []);
      })
      .catch(error => {
        console.error('Error fetching data:', error);
      });
  }

  const handleMouseDown = (e) => {
    e.preventDefault();
  };

  const getlocationwithid = (selectedCity) => {
    axios.get(`${apiURL}/get_location_name?location_name=${selectedCity}`, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    })
      .then((response) => {
        if (response.data && response.data.length > 0) {

          const cityId = response.data[0].city_id;
          sessionStorage.setItem('citynameheadernewapicityid', response.data[0].city_id);
          sessionStorage.setItem('citynameheadernewapi', response.data[0].location_name);

          sessionStorage.setItem('citynameheadernewapicityid1', response.data[0].city_id);
          sessionStorage.setItem('citynameheadernewapi1', response.data[0].location_name);
          setcityname(response.data[0].location_name);
        }
        else {
        }
      })
      .catch((error) => {

        if (error.response && error.response.status === 404) {
          toast.error("This location is currently unserviceable.")
          setTimeout(() => {
            navigate('/Errorpagenew');
          }, 1000);
        } else {
          console.error("An error occurred:", error);
        }
      });
  };
  const handleDefaultCity = () => {
    sessionStorage.setItem('citynameheadernewapi', 'Chennai');

    axios.get(`${apiURL}/get_location_name?location_name=Chennai`, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    })
      .then((response) => {
        if (response.data && response.data.length > 0) {
          const cityId = response.data[0].city_id;
          const cityName = response.data[0].location_name;
          sessionStorage.setItem('citynameheadernewapicityid', response.data[0].city_id);
          sessionStorage.setItem('citynameheadernewapi', response.data[0].location_name);
          sessionStorage.setItem('citynameheadernewapicityid1', response.data[0].city_id);
          sessionStorage.setItem('citynameheadernewapi1', response.data[0].location_name);
        }
      })
      .catch((error) => {
        console.error('Error fetching city details:', error);
      });
  };


  return (
    <div>
      <header class={logo == 'minilogo' ? 'header' : "header header1"}>
        <div class="container-fluid container-xl ">
          <nav class="navbar navbar-expand-lg"> <a class="navbar-brand">
            <img src={Images.logo} className='logo-img ml-0 mr-5' onClick={redirect} />
          </a>
            <i className="text-secondary mx-2 heading_color" aria-hidden="true" >
              <FontAwesomeIcon icon={Icons.faLocationDot} />
            </i>
            <select
              className='form-select location-drop'
              value={cityname || city}
              onChange={handleChange}
            >
              {!cityname && (
                <option value={cityname}>Chennai</option>
              )}
              {citylist.map(cityItem => (
                <option key={cityItem.id} value={cityItem.city_name}>
                  {cityItem.city_name}
                </option>
              ))}
            </select>
            <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav"
              aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
              <span class="navbar-toggler-icon"></span>
            </button>
            <div class="collapse navbar-collapse justify-content-end" id="navbarNav">
              <ul class="navbar-nav">
                <li class="nav-item mt-2">
                  <NavLink to='/Contact_Us' className='heading_color contact-us' onClick={() => window.scrollTo(0, 0)}>Contact Us</NavLink>
                </li>
                <li class="nav-item mt-1">
                  <NavLink to='/ListVenue'>
                    <button className='btn header-btn' onClick={() => window.scrollTo(0, 0)}>List your Venue</button>
                  </NavLink>
                </li>
              </ul>
            </div>
          </nav>
        </div>
      </header>
    </div>
  )
}

export default Header
