import Images from "../../../Images";
export const responsive = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 1024 },
    items: 6,
    slidesToSlide: 2,
  },
  desktop: {
    breakpoint: { max: 1024, min: 800 },
    items: 5,
  },
  tablet: {
    breakpoint: { max: 800, min: 464 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};

export const productData = [
  {
    id: 1,
    imageurl: Images.carousel1,
    name: "Colorful sneakers",

  },
  {
    id: 2,
    imageurl: Images.carousel2,
    name: "Sport sneakers",

  },
  {
    id: 3,
    imageurl: Images.carousel3,
    name: "iWatch",

  },
  {
    id: 4,
    imageurl: Images.carousel4,
    name: "Water Bottle",

  },
  {
    id: 5,
    imageurl: Images.carousel5,
    name: "Vans sneakers",

  },
  {
    id: 6,
    imageurl:
      Images.carousel6,
    name: "Coco Noir",

  },
];
