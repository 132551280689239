import React, { useContext, useState, useEffect } from 'react';
import { MainContext } from '../../../Store/Context';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Icons } from '../../../Icons/IconIndex';

function Navbar({ city,active,setActive }) {
    const { setUser, user } = useContext(MainContext);
    
    useEffect(() => {
        const storedCityName = sessionStorage.getItem('citynamebind in index'); 
    }, []);

    const toggleNavbar = () => {
        setActive(!active);
    };

    return (
        <nav className="navbar navbar-main navbar-expand-lg position-sticky mt-5 top-1 px-0 mx-4 shadow-none border-radius-xl z-index-sticky card" id="navbarBlur" data-scroll="true">
            <div className="container-fluid py-1 px-3">
                <div className="sidenav-toggler sidenav-toggler-inner d-xl-block d-none">
                    <a href="javascript:;" className="nav-link text-body p-0" onClick={() => setUser(!user)}>
                        <div className="sidenav-toggler-inner">
                            <svg width="38" height="38" viewBox="0 0 19 19" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                <path d="M2 3.5a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5zm.646 2.146a.5.5 0 0 1 .708 0l2 2a.5.5 0 0 1 0 .708l-2 2a.5.5 0 0 1-.708-.708L4.293 8 2.646 6.354a.5.5 0 0 1 0-.708zM7 6.5a.5.5 0 0 1 .5-.5h6a.5.5 0 0 1 0 1h-6a.5.5 0 0 1-.5-.5zm0 3a.5.5 0 0 1 .5-.5h6a.5.5 0 0 1 0 1h-6a.5.5 0 0 1-.5-.5zm-5 3a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5z"></path>
                            </svg>
                        </div>
                    </a>
                </div>
                <button className="navbar-toggler" type="button" onClick={toggleNavbar}>
                    <span className="navbar-toggler-icon"></span>
                </button>
            </div>
        </nav>
    );
}

export default Navbar;
