import React, { useEffect, useRef } from 'react'
import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Images from '../../../Images';
import { faCodeFork, faLocationDot } from '@fortawesome/free-solid-svg-icons'
import { Icons } from '../../../Icons/IconIndex'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Toaster, toast } from 'react-hot-toast';
import Axios from 'axios';
import { apiURL } from '../../../Layout/Common/confiqurl';
import { Link, useNavigate } from 'react-router-dom';

import { maxLength, numberMethod, validPasswordRegex, validEmailRegex, validPhoneNumberRegex } from '../../../Layout/Common/common';

const About = ({ allData ,startDate,endDate}) => {
    const navigate = useNavigate()
    const today = new Date().toISOString().split('T')[0];
    const [form, setForm] = useState(false)
    const [show, setShow] = useState(false);
    const [show1, setShow1] = useState(false);
    const [alertShow, setAlertShow] = useState("")
    const [events, setevents] = useState([]
    )
    const [checkin, setcheckin] = useState('')
    const [checkout, setcheckout] = useState('')

    const [alertMessage, setAlertMessage] = useState('');

    const [alertMessage1, setAlertMessage1] = useState('');


    useEffect(() => {
        EventData();
        const checkin = sessionStorage.getItem("checkin") || "";
        const checkout = sessionStorage.getItem("checkout") || "";
        setStateData((prevState) => ({
            ...prevState,
            select_from_date: checkin,
            select_to_date: checkout
        }));
    }, []);
    const handleDateChange = (e) => {
        const { value } = e.target;

        const selectedDate = new Date(value);
        const currentDate = new Date();
        currentDate.setHours(0, 0, 0, 0);

        if (selectedDate < currentDate) {
            setAlertMessage("Please Select a Proper Date. The Check-in Date Cannot be Before Today's Date.");
            return;

        }

        setStateData((prevState) => ({
            ...prevState,
            select_from_date: value
        }));
        setAlertMessage('');
    };

    const handlecheckchange = (e) => {
        const { value } = e.target;

        const selectedDate = new Date(value);
        const checkinDate = new Date(stateData.select_from_date);
        checkinDate.setHours(0, 0, 0, 0);

        if (selectedDate < checkinDate) {
            setAlertMessage1("Check-out date cannot be before check-in date.");
            return;
        }

        setStateData((prevState) => ({
            ...prevState,
            select_to_date: value
        }));
        setAlertMessage1('');
    };

    const [state, setState] = useState({

        first: "",
        second: "",
        third: "",
        forth: "",
        fifth: "",
        sixth: "",


    })

    const firstRef = useRef(null);
    const secondRef = useRef(null);
    const thirdRef = useRef(null);
    const forthRef = useRef(null);
    const fifthRef = useRef(null);
    const sixthRef = useRef(null);


    const [stateData, setStateData] = useState({

        email_id: "",
        enter_event_organizer: '',
        select_end_time: "",
        select_start_time: "",
        select_to_date: endDate,
        select_from_date: startDate,
        event_type: "",
        place_name: "",
        checkin: "",
        checkout: "",

    })


    const handleDataChange = (event) => {
        const { name, value } = event.target;

        setStateData((prevState) => ({
            ...prevState,
            [name]: value
        }));

        let fromDate = new Date(stateData.select_from_date);
        let toDate = new Date(stateData.select_to_date);
        let startTime = stateData.select_start_time;
        let endTime = stateData.select_end_t
        if (name === 'select_from_date' || name === 'select_to_date') {
            fromDate = name === 'select_from_date' ? new Date(value) : fromDate;
            toDate = name === 'select_to_date' ? new Date(value) : toDate;

            if (fromDate > toDate) {
                toast.error("To Date must be after From Date.");
                return;
            }
        }
        if (name === 'select_start_time' || name === 'select_end_time') {
            startTime = name === 'select_start_time' ? value : startTime;
            endTime = name === 'select_end_time' ? value : endTime;

            if (fromDate.toDateString() === toDate.toDateString() && startTime && endTime && startTime >= endTime) {
                toast.error("End Time must be greater than Start Time.");
                return;

            }

        }
    };

    const handleAlertClose = () => setAlertShow(false);
    const handleAlertOpen = () => setAlertShow(true);

    const handleFormClose = () => setForm(false);
    const handleFormShow = () => setForm(true);

    const handleClose1 = () => setShow1(false);
    const handleShow1 = () => setShow1(true);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [inputValue, setInputValue] = useState('');
    const [inputPhone, setInputPhone] = useState('');


    const [inputUser, setInputUser] = useState('');

    const handleInputUserChange = (event) => {
        const capitalizedValue = event.target.value.charAt(0).toUpperCase() + event.target.value.slice(1);
        setInputUser(capitalizedValue);
    }
    const handleChange = (event) => {
        const { name, value } = event.target;
        if (!isNaN(value) && value.length <= 1) {
            setState((prevState) => ({
                ...prevState,
                [name]: value
            }));

            if (value !== '') {
                switch (name) {
                    case 'first':
                        if (secondRef.current) secondRef.current.focus();
                        break;
                    case 'second':
                        if (thirdRef.current) thirdRef.current.focus();
                        break;
                    case 'third':
                        if (forthRef.current) forthRef.current.focus();
                        break;
                    case 'forth':
                        if (fifthRef.current) fifthRef.current.focus();
                        break;
                    case 'fifth':
                        if (sixthRef.current) sixthRef.current.focus();
                        break;
                    default:
                        break;
                }
            }
        }
    };


    const handleInputChange = (event) => {
        const value = event.target.value;
        if (/^\d{0,10}$/.test(value)) {
            setInputValue(value);
        }
    };


    const handlePhoneChange = (event) => {
        const value = event.target.value;
        if (/^\d{0,10}$/.test(value)) {
            setInputPhone(value);
        }
    };





    const EventData = () => {
        Axios.get(`${apiURL}/get_eventType`, {
            headers: {

                "accept": "application/json",
                "Content-Type": " application/json",
            }
        },)
            .then((response) => {

                setevents(response.data)
            })
    }
    const storageValue = () => {
        setInputPhone(sessionStorage.getItem("number"))
    }

    const handleclick = (e) => {
        const project = JSON.parse(sessionStorage.getItem('data'));
        e.preventDefault();



        if (!stateData.event_type) {
            toast.error('Select Event Type');
            return;
        }

        if (!inputUser) {
            toast.error('Please Enter Organizer Name');
            return;
        }

        if (!inputValue || !validPhoneNumberRegex.test(inputValue)) {
            toast.error('Please Enter a Valid Mobile Number');
            return;
        }

        if (!stateData.email_id || !validEmailRegex.test(stateData.email_id)) {
            toast.error('Please Enter a Valid Email');
            return;
        }

        if (!stateData.select_from_date && !checkin) {
            toast.error('Please Enter Start Date');
            return;
        }

        if (!stateData.select_to_date && !checkout) {
            toast.error('Please Enter End Date');
            return;
        }
        if (!stateData.select_to_date && !checkout) {
            toast.error('Please Enter End Date');
            return;
        }

        if (new Date(stateData.select_to_date) < new Date(stateData.select_from_date)) {
            toast.error('End Date cannot be before Start Date');
            return;
        }

        if (!stateData.select_start_time) {
            toast.error('Please Enter Start Time');
            return;
        }

        if (!stateData.select_end_time) {
            toast.error('Please Enter End Time');
            return;
        }

        const startDateTime = new Date(`${stateData.select_from_date}T${stateData.select_start_time}`);
        const endDateTime = new Date(`${stateData.select_to_date}T${stateData.select_end_time}`);

        if (endDateTime <= startDateTime) {
            toast.error('End Time should be after Start Time');
            return;
        }
        const payload = {
            place_name: allData.mandapam_name,
            event_type: stateData.event_type,
            name: inputUser,
            contact_number: inputValue,
            start_date: stateData.select_from_date,
            end_date: stateData.select_to_date,
            start_time: stateData.select_start_time,
            end_time: stateData.select_end_time,
            updated_by: "kkkkkkk",
            email_id: stateData.email_id,

        };

        Axios.post(`${apiURL}/EnquiyList_remove_conditiondate12?Mandapam_id=${allData.id}`, payload, {
            headers: {
                "accept": "application/json",
                "Content-Type": "application/json",
            }
        })
            .then((response) => {
                handleShow();
                sessionStorage.removeItem("checkin");
                sessionStorage.removeItem("checkout");
                sessionStorage.setItem("number", response.data.Number);
                sessionStorage.setItem("id", response.data.id);

                storageValue();
                setStateData([]);

                setcheckin('');
                setcheckout('');
                setStateData({
                    select_from_date: '',
                    select_to_date: '',
                    select_start_time: '',
                    select_end_time: '',
                    inputValue: '',
                    inputUser: '',
                    inputPhone: '',
                    name: '',
                    contact_number: '',

                });

                handleFormClose();
            })
            .catch((error) => {
                console.error("Error:", error);

                if (error.response) {
                    if (error.response.status === 500) {
                        navigate('/Errorpage');
                    } else if (error.response.status === 404) {
                        navigate('/ErrorpageNew');
                    } else {
                        const errorMessage = error.response.data && error.response.data.detail
                            ? error.response.data.detail
                            : "An error occurred while submitting the form.";
                        toast.error(errorMessage);
                    }
                } else {
                    toast.error("An error occurred while submitting the form.");
                }
            });

        sessionStorage.removeItem("number");
    };
    const handleMobileClick = (e) => {

        const id = sessionStorage.getItem("id")
        if (validPhoneNumberRegex.test(inputPhone)) {

            Axios.post(`${apiURL}/Enquiry_OTP_api?id=${id}&mob=${inputPhone}`, {
                headers: {
                    "accept": "application/json",
                }
            },
            ).then((response) => {
                sessionStorage.setItem("number", response.data.OTP)
                handleShow1()
                handleSplit()
                setStateData({
                    email_id: "",
                    enter_event_organizer: "",
                    select_end_time: "",
                    select_start_time: "",
                    select_to_date: "",
                    select_from_date: "",
                    event_type: "",
                    place_name: "",
                    checkin: "",
                    checkout: "",
                    inputUser: "",
                    inputPhone: "",
                    name: "",
                    contact_number: "",
                });


            })
                .catch((err) => {
                    {
                        toast.error(err)
                    }
                });

        } else {
            toast.error('Please Enter Valid Mobile Number');
        }

    }


    useEffect(() => {

        EventData()
    }, []);

    const handleSplit = (otp) => {
        if (otp && otp.length === 6 && /^\d+$/.test(otp)) {
            const [first, second, third, fourth, fifth, sixth] = otp.split("");
            setState({ first, second, third, fourth, fifth, sixth });
        } else {
            setState({ first: '', second: '', third: '', fourth: '', fifth: '', sixth: '' });
        }
    }

    const handleOtpClick = (e) => {

        const data = `${state.first}${state.second}${state.third}${state.forth}${state.fifth}${state.sixth}`
        const id = sessionStorage.getItem("id")

        const token = JSON.parse(sessionStorage.getItem('data'));

        const options = {

            Enter_OTP: data,

        }

        Axios.post(`${apiURL}/verify_otp?id=${id}`, options, {
            headers: {
                "accept": "application/json",
                "Content-Type": " application/json",
            }
        },
        ).then((response) => {

            handleClose1()
            handleClose()
            handleFormClose()
            handleAlertOpen()
            setStateData({

                email_id: "",
                enter_event_organizer: "",
                select_end_time: "",
                select_start_time: "",
                select_to_date: "",
                select_from_date: "",
                event_type: "",
                place_name: "",
                checkin: "",
                checkout: "",
                inputUser: "",
                inputUser: "",
                inputPhone: "",
                name: "",
                contact_number: "",
                eventOrganizer: '',
                mobileNumber: '',

            });
        })
            .catch((err) => {
                {
                    toast.error("Please Enter Correct OTP")
                }
            });
    }
    return (
        <div className='px-3  mx-5  mb-5'>
            <Toaster toastOptions={{ position: "top-right" }} />

            <h4 className='text-black my-5 mx-1'>{allData && allData.mandapam_name ? allData.
                mandapam_name : ""} </h4>
            <div className='row mt-3'>
                <div className='col-md-12 col-xl-7 col-lg-7 px-3'>
                    <p className='' style={{ fontSize: "15px" }}>{allData && allData.about}</p>
                </div>
                <div className='col-md-12 col-xl-5 col-lg-5 px-3 card'>
                    <div className='row mt-5 '>
                        <div className='col-xl-6 col-lg-6 col-md-12 px-3 '>
                            <h6>Check in</h6>
                            <div >
                                <input className="form-select-md p-1 w-100 border border-black" type='Date'
                                    value={checkin == "" ? stateData.select_from_date : checkin}
                                    onChange={handleDateChange} onFocus={(e) => e.target.showPicker()} />
                            </div>

                            {alertMessage && (
                                <div style={{
                                    padding: '15px',
                                    marginBottom: '20px',
                                    border: '1px solid transparent',
                                    borderRadius: '4px',
                                    color: '#a94442',
                                    backgroundColor: '#f2dede',
                                    borderColor: '#ebccd1',
                                    marginTop: '15px'
                                }}>
                                    {alertMessage}
                                </div>
                            )}

                        </div>
                        <div className='col-xl-6 col-lg-6 col-md-12'>
                            <h6>Check Out</h6>
                            <div>
                                <input className="form-select-md p-1 w-100 border border-black "
                                    value={checkout == "" ? stateData.select_to_date : checkout} min={today}
                                    onChange={handlecheckchange} onFocus={(e) => e.target.showPicker()}
                                    type='Date' />
                            </div>

                            {alertMessage1 && (
                                <div style={{
                                    padding: '15px',
                                    marginBottom: '20px',
                                    border: '1px solid transparent',
                                    borderRadius: '4px',
                                    color: '#a94442',
                                    backgroundColor: '#f2dede',
                                    borderColor: '#ebccd1',
                                    marginTop: '15px'
                                }}>
                                    {alertMessage1}
                                </div>
                            )}

                        </div>
                        <div className='col-xl-12 col-md-12 col-lg-12' style={{
                                display: "flex",justifyContent:'center',
                            }}>
                            <button type="button" className="btn text-white mt-4 mb-3" style={{
                                backgroundColor: " #836D5D",
                            }} onClick={handleFormShow}>Check Availability</button>
                        </div>
                    </div>

                </div>

            </div>
            <Modal size='xl' show={form} >
                <Modal.Header className="d-flex justify-content-between align-items-center">
                    <div className="flex-grow-1 text-center">
                        <Modal.Title style={{ fontFamily: 'open sans', fontWeight: '600' }}>Enquire Form</Modal.Title>
                    </div>
                    <button className='me-3 btn btn-outline-secondary  ' onClick={handleFormClose}>
                        x
                    </button>
                </Modal.Header>

                <Modal.Body className='d-flex justify-content-center mb-5'>
                    <div>

                        <div className="container">
                            <div className="row mt-5">
                                <div className="col-lg-6  ">
                                    <img src={Images.logo4} alt='' className='h-100 w-100 mb-5' style={{ backgroundSize: "cover" }} />

                                </div>
                                <div className="col-lg-6 ps-4 ">
                                    <div className="card card-plain " >

                                        <div className="card-body " style={{ paddingTop: "0px" }}>
                                            <form role="form">

                                                <div className='' >
                                                    <label>Event Type <span style={{ color: 'red' }}>*</span> </label>

                                                    <select id="inputState" className="form-select mx-1  mb-2" name='event_type' value={stateData.event_type} onChange={handleDataChange} style={{ cursor: "pointer", fontSize: "12px", color: "#212529" }}>
                                                        <option value="" disabled selected hidden>
                                                            Select an option
                                                        </option>

                                                        {
                                                            events.map((data) => {

                                                                return (
                                                                    <option style={{ cursor: "pointer" }}> {data.data_value}</option>

                                                                )
                                                            })
                                                        }
                                                    </select>

                                                </div>

                                                <label className='' >Event Organizer <span style={{ color: 'red' }}>*</span></label>
                                                <div className="">
                                                    <input type="text" className="form-control mx-1" placeholder="Event Organizer" aria-label="username" name='enter_event_organizer' value={inputUser} onChange={handleInputUserChange} style={{ fontSize: "12px", color: "#212529" }} />
                                                </div>
                                                <label className='mt-3'>Mobile Number <span style={{ color: 'red' }}>*</span> </label>
                                                <div className="">
                                                    <input
                                                        className="form-control mx-1"
                                                        placeholder='Mobile Number'

                                                        type="text"
                                                        id="input"
                                                        maxLength={10}
                                                        pattern="[0-9]*"
                                                        value={inputValue}
                                                        onChange={handleInputChange}
                                                        style={{ fontSize: "12px", color: "#212529" }}
                                                    />                                        </div>
                                                <label className='mt-3'>Email <span style={{ color: 'red' }}>*</span> </label>
                                                <div className="">
                                                    <input type="email" className="form-control mx-1 mb-2"
                                                        placeholder="Email" aria-label="Email" name='email_id'
                                                        value={stateData.email_id} onChange={handleDataChange}
                                                        style={{ fontSize: "12px", color: "#212529" }} />
                                                </div>


                                                <div className='' >
                                                    <label>Venue Name  </label>
                                                    <input className="form-control mx-1" type="text" placeholder='place Name ' name='place_name' style={{ fontSize: "12px", color: "#212529" }} value={allData && allData.mandapam_name} disabled />
                                                </div>
                                                <div className='row mt-3'>
                                                    <div className='col-6' >
                                                        <label> From Date <span style={{ color: 'red' }}>*</span></label>
                                                        <input className="form-control mx-1" type="Date" placeholder='Enter  From Date ' name='select_from_date'
                                                            value={stateData.select_from_date || checkin}
                                                            min={today} onChange={handleDataChange} onFocus={(e) => e.target.showPicker()} style={{ fontSize: "12px", color: "#212529" }} />
                                                    </div>
                                                    <div className='col-6' >
                                                        <label> To Date <span style={{ color: 'red' }}>*</span></label>

                                                        <input className="form-control" type="Date" placeholder='Enter To Date' name='select_to_date'
                                                            value={stateData.select_to_date || checkout} min={today} onChange={handleDataChange} onFocus={(e) => e.target.showPicker()} style={{ fontSize: "12px", color: "#212529" }} />


                                                    </div>

                                                </div>
                                                <div className='row mt-3'>
                                                    <div className='col-6' >
                                                        <label> Start Time <span style={{ color: 'red' }}>*</span></label>
                                                        <input className="form-control" type="time" placeholder='Enter Start Time' name='select_start_time' value={stateData.select_start_time} onFocus={(e) => e.target.showPicker && e.target.showPicker()} onChange={handleDataChange} />
                                                    </div>
                                                    <div className='col-6' >
                                                        <label> End Time <span style={{ color: 'red' }}>*</span></label>

                                                        <input className="form-control" type="time" placeholder='Enter End Time' name='select_end_time' value={stateData.select_end_time} onFocus={(e) => e.target.showPicker && e.target.showPicker()} onChange={handleDataChange} />


                                                    </div>

                                                </div>


                                                {alertMessage && (
                                                    <div style={{
                                                        padding: '15px',
                                                        marginBottom: '20px',
                                                        border: '1px solid transparent',
                                                        borderRadius: '4px',
                                                        color: '#a94442',
                                                        backgroundColor: '#f2dede',
                                                        borderColor: '#ebccd1',
                                                        marginTop: '15px'
                                                    }}>
                                                        {alertMessage}
                                                    </div>
                                                )}
                                                <div className="text-center mt-4">
                                                    <button type="button" className="btn text-white  w-100 mt-4 mb-0" style={{ backgroundColor: "#836D5D" }} onClick={(event) => { handleclick(event) }}>Submit</button>
                                                </div>
                                            </form>
                                        </div>

                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </Modal.Body>

            </Modal>
            <Modal size='lg' show={show} >
                <Modal.Header className='d-flex justify-content-end '>
                    <button className='me-3 btn btn-outline-secondary  ' onClick={handleClose}>
                        x
                    </button>
                </Modal.Header>
                <Modal.Body className='d-flex justify-content-center'>
                    <div className="card w-75 ">

                        <div className=" pb-0 text-center">
                            <h6 className="font-weight-bolder">Verify Your Mobile Number to Contact Venue</h6>
                            <p className="mb-0 mt-2">You will Receive 6 DIgit Code To verify next</p>
                        </div>
                        <div className="card-body">
                            <form role="form">
                                <label>Mobile Number</label>

                                <div className="mb-3">


                                    <input
                                        placeholder='  mobile number'
                                        className="form-control"
                                        type="text"
                                        id="input"
                                        maxLength={10}
                                        pattern="[0-9]*"
                                        value={inputPhone}
                                        onChange={handlePhoneChange}
                                        disabled
                                    />

                                </div>
                                <div className="text-center">
                                    <button type="button" className="btn text-white" onClick={handleMobileClick} style={{ backgroundColor: " #836D5D" }}>Submit</button>
                                </div>
                            </form>
                        </div>
                    </div>

                </Modal.Body>

            </Modal>
            <Modal size='lg' show={show1} >
                <Modal.Header className='d-flex justify-content-end '>
                    <button className='me-3 btn btn-outline-secondary  ' onClick={handleClose1}>
                        x
                    </button>
                </Modal.Header>
                <Modal.Body className='d-flex justify-content-center'>
                    <div className="card w-75 ">

                        <div className=" pb-0 text-center">
                            <h5 className="font-weight-bolder">OTP</h5>
                        </div>
                        <div className="card-body">
                            <form role="form">
                                <h6 className='text-center'>Please Enter a 6 Digit OTP Sent by us</h6>

                                <div className="row w-100  mx-auto ">
                                    <div className="col">
                                        <div className="form-group">
                                            <input type="text" className="form-control form-control-lg"style={{textAlign:'ceneter'}} maxlength="1" name='first' value={state.first} onChange={handleChange} ref={firstRef} />
                                        </div>
                                    </div>
                                    <div className="col">
                                        <div className="form-group"  style={{ textAlign: 'center' }}>
                                            <input type="text" className="form-control form-control-lg" maxlength="1" name='second' value={state.second} onChange={handleChange} ref={secondRef} />
                                        </div>
                                    </div>
                                    <div className="col">
                                        <div className="form-group">
                                            <input type="text" className="form-control form-control-lg"  style={{ textAlign: 'center' }}maxlength="1" name='third' value={state.third} onChange={handleChange} ref={thirdRef} />
                                        </div>
                                    </div>
                                    <div className="col">
                                        <div className="form-group">
                                            <input type="text" className="form-control form-control-lg"   style={{ textAlign: 'center' }}maxlength="1" name='forth' value={state.forth} onChange={handleChange} ref={forthRef} />
                                        </div>
                                    </div>

                                    <div className="col">
                                        <div className="form-group">
                                            <input type="text" className="form-control form-control-lg"  style={{ textAlign: 'center' }} maxlength="1" name='fifth' value={state.fifth} onChange={handleChange} ref={fifthRef} />
                                        </div>
                                    </div>
                                    <div className="col">
                                        <div className="form-group">
                                            <input type="text" className="form-control form-control-lg"   style={{ textAlign: 'center' }}maxlength="1" name='sixth' value={state.sixth} onChange={handleChange} ref={sixthRef} />
                                        </div>
                                    </div>
                                </div> 
                                <div className="text-center">
                                    <button type="button" className="btn text-white" onClick={handleOtpClick} style={{ backgroundColor: " #836D5D" }}>Submit</button>
                                </div>
                            </form>
                        </div>
                    </div>

                </Modal.Body>

            </Modal>
            <Modal size='md' show={alertShow} >
                <Modal.Header className='d-flex justify-content-between '>
                    <h4>Confirmation</h4>
                    <button className='me-3 btn btn-outline-secondary  ' onClick={handleAlertClose}>
                        x
                    </button>
                </Modal.Header>
                <Modal.Body className='d-flex justify-content-center'>

                    <h6 className='mx-4 text-center'>Thank you! Our Relationship manager will check the availability and contact you soon.</h6>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={handleAlertClose}>
                        Ok
                    </Button>



                </Modal.Footer>
            </Modal>

        </div>
    )
}

export default About