import { faUser } from "@fortawesome/free-regular-svg-icons";
import { faAirFreshener, faCamera, faCircle, faCodeFork, faCoffee, faFireExtinguisher, faLocationDot, faMoneyBill, faMoneyBill1Wave, faParking, faRestroom, faWifi,faCar , faBicycle} from "@fortawesome/free-solid-svg-icons";
import { Avatar } from "@material-ui/core";
import Images from "../../../Images";

export const data = [
    {
        name: "Hall Capacity",
        icons: faUser,
        value: "700"
    },
    {
        name: "Air Conditioning",
        icons: faAirFreshener,
        value: "yes"
    },
    {
        name: "Food Type",
        icons: faCodeFork,
        value: "Veg/Non Veg"
    },
    {
        name: "Food Price",
        icons: faMoneyBill1Wave,
        value: "$600"
    },
    {
        name: "Rooms",
        icons: faRestroom,
        value: "30AC"
    },
    {
        name: "Rooms",
        icons: faLocationDot,
        value: "3, 27th Cross Rd, Banashankari Stage II, Banashankari, Bengaluru, Karnataka 560070"
    },
    {
        name: "startingpriceperday",
        icons: faMoneyBill1Wave     ,
        value: "30AC"
    },
    {
        name: "Carparking",
        icons: faCar,
        value: "30AC"
    },
    {
        name: "Bikeparking",
        icons:  faBicycle,
        value: "30AC"
    },

]


export const variable = [
    {
        name: "wifi",
        icons: faWifi,
        names: "Parking",
        iconss: faParking,
    },
    {
        name: "Break Fast",
        icons: faCodeFork,
        names: "Hot Water",
        iconss: faCircle,
    },
    {
        name: "Coffee",
        icons: faCoffee,
        names: "Parking",
        iconss: faCamera,
    },
    {
        name: "Food Price",
        icons: faMoneyBill1Wave,
        value: "$600"
    },
    {
        name: "Priceperday",
        icons: faMoneyBill1Wave,
        value: "$600"
    },
    {
        name: "Extinguisher",
        icons: faFireExtinguisher,
    },
    {
        name: "AC",
        icons: faAirFreshener,
    }, 
    {
        name: "Carparking",
        icons:    faCar,
    },
    {
        name: "Bikeparking",
        icons:     faBicycle,
    },

]

export const Review = [
    {
        avatar: Images.profile1,
        name: "Darrell Steward"
    },
    {
        avatar: Images.profile2,
        name: "Robert Fox"
    }, {
        avatar: Images.profile3,
        name: "Jenny Wilson"
    }, {
        avatar: Images.profile1,
        name: "Eren Yeager"
    }, {
        avatar: Images.profile2,
        name: "Jenny Wilson"
    }, {
        avatar: Images.profile3,
        name: "Eren Yeager"
    },
] 