import React, { useState, useEffect } from 'react';
import { Icons } from '../../../Icons/IconIndex'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Images from '../../../Images'
import "../Style.css"
import axios from 'axios';
import { apiURL } from '../../../Layout/Common/confiqurl';
import { LoaderIcon } from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';

const Banner6 = ({ city, arealist, locality }) => {
    const navigate = useNavigate();
    const [citylist, setcitylist] = useState([])
    const [venues, setvenues] = useState([]);
    const [cityname, setCityname] = useState('');
    const [selectedLocality, setSelectedLocality] = useState('');
    const [selectedVenue, setSelectedVenue] = useState(null);
    const [locationlist, setLocationlist] = useState(() => {
        const savedLocationList = sessionStorage.getItem('locationName');
        return savedLocationList ? JSON.parse(savedLocationList) : [];
    });
    const selectedCityIds = JSON.parse(sessionStorage.getItem('selectedCityIds')) || [];
    const [locationname, setlocationname] = useState([])
    const [advancedcityid, setadvancedcityid] = useState();
    const [cityid, setcityid] = useState();
    const [hallcount, sethallcount] = useState('')
    const [values, setValues] = useState({ min: 0, max: 100000 });
    const [selectedPlaceOption, setSelectedPlaceOption] = useState([]);
    const [citynameheader, setcitynameheader] = useState();
    const myFunction = () => {
        const storedCityName = sessionStorage.getItem('citynameheadernewapi');
        if (storedCityName) {
            setCityname(storedCityName);
        } else {
            console.error('storedCityName is null or undefined');
            setCityname('Chennai');
        }
    };
    useEffect(() => {
        getcitylist();
        fetchVenues();
        const storedArealist = sessionStorage.getItem('arealist');
    }, []);
    const staticcityname = [
        'Mumbai',
        'Pune',
        'Bangalore,',
        'Kolkata',
        'Chennai',
        'Lucknow',
        'Jaipur',
        'Ahmedabad,',
        'Hyderabad',
        'Bhopal',
        'Agra',
        'Kochi',
        'Coimbatore',
        'Bhopal',
        'Thane',

    ];
    const fetchVenues = async () => {
        try {
            const response = await axios.get(`${apiURL}/get_allvenue_types`);
            setvenues(response.data.place_types);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };
    const firstTwoLocalities = locationlist.slice(0, 2);
    const imagePaths = [
        Images.foot_1,
        Images.foot_2,
        Images.foot_3,
        Images.foot_4,
        Images.foot_5,
        Images.image_6,
        Images.image_8,
        Images.image_9,
        Images.image_10,
        Images.foot_4,
        Images.foot_1,
        Images.image_9,
        Images.image_10,
        Images.image_11,
        Images.image_12,
        Images.image_10,
        Images.foot_1,
        Images.foot_5,
        Images.foot_4,
        Images.foot_2,
        Images.foot_2,
        Images.image_9,
        Images.foot_3,
    ];
    const handleLocalityClick = (footercities, city, venue) => {
        setSelectedLocality(footercities);
        setSelectedVenue(venue)
        sessionStorage.setItem('footercity store', (footercities));
        sessionStorage.setItem('citynameheadernewapi1', (city));
        fetchcityID(footercities);
        const cityId = footercities.id;
    };
    const fetchcityID = (footercities) => {
        axios.get(`${apiURL}/get_location_name?location_name=${footercities}`, {
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
        })
            .then((response) => {
                if (response.data && response.data.length > 0) {
                    const cityData = response.data[0];
                    setcityid(cityData.city_id);
                    setcitynameheader(cityData.location_name);
                } else {
                    console.log('');
                }
            })
            .catch((error) => {
                console.error('Error fetching city ID:', error);
            });
    };
    const getcitylist = () => {
        axios.get(`${apiURL}/cities`)
            .then(response => {
                setcitylist(response.data.data || []);
            })
            .catch(error => {
                console.error('Error fetching data:', error);
            });
    }
    return (
        <div className='mt-5 row px-0 mx-0'>
            <div className='col-md-12 col-xl-9 col-lg-9'>
                <div className='row px-0 mx-0'>
                    {venues.map((venue, index) => (
                        <div className='col-md-12 col-xl-4 col-lg-4' key={index}>
                            <div className='row px-0 mx-0'>
                                <div className='col-md-12 p-1'>
                                    <p className='footer_css mx-3'>
                                        <img src={imagePaths[index]} alt={`Image of ${venue}`} className='foot-img' />
                                        {venue}
                                    </p>
                                    <div className='footer-link p-3'>
                                        {citylist.map((city, cityIndex) => (
                                            <Link
                                                to={'/Advanced_Search'}
                                                state={{
                                                    city_id: advancedcityid,
                                                    ID: city.id,
                                                    advancedcityid,
                                                    location: [city.city_name],
                                                    hall: hallcount,
                                                    budget: values,
                                                    mlocation: selectedPlaceOption,
                                                    venue: [venue],
                                                    selectedCity: city,
                                                }}
                                                key={cityIndex}>
                                                <a
                                                    className='footer_links p-2'
                                                    onClick={() => handleLocalityClick(city, city.city_name, venue)
                                                    }>
                                                    {venue} in {city.city_name}{cityIndex !== citylist.length - 1 && " | "}
                                                </a>
                                            </Link>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            <div className='col-md-12 col-xl-3 col-lg-3 mt-2 p-2'>
                <div className='row'>
                    <div className='col-md-12' style={{ padding: ' 0px 30px 0px 30px' }}>
                        <h6 className='footer_css'>Contact Us </h6>
                        <div className='d-flex justify-content-start mt-4'>
                            <FontAwesomeIcon icon={Icons.faLocationDot} className='mt-2' style={{ color: "#836D5D" }} />
                            <p className='ms-1 '>
                                G2, Plot 18, Indira Nagar, Kiskintha Road, Old Perugalathaur,
                                Tambaram, Kanchipuram-600063,Tamil Nadu</p>
                        </div>
                        <div className='d-flex justify-content-start'>
                            <FontAwesomeIcon icon={Icons.faPhone} className='mt-2' style={{ color: "#836D5D" }} />
                            <p className='ms-2'>(+1) 9239999999</p>
                        </div>
                        <div className='d-flex justify-content-start'>
                            <FontAwesomeIcon icon={Icons.faMessage} className='mt-4 pt-2' style={{ color: "#836D5D" }} />
                            <p className='ms-2 mt-4'>contact@bookmevenue.com</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default Banner6